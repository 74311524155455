<template>
  <div>
    <Page :tabList="tabList"></Page>
  </div>
</template>

<script>
import Page from "../../components/public/Page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {
      tabList: [
        { name: "供应商名单", path: "/Supplier" },
        { name: "年度审核计划", path: "/AnnualAuditPlan" },
        { name: "问题综合表", path: "/ProblemSynthesis" },
        { name: "资料综合表", path: "/DataSynthesis" },
        { name: "供应商审核题库", path: "/SupplierQuestion" },
      ],
    };
  },
};
</script>

<style>
</style>